<template>
  <div>
    <b-card title="Liste des navires">
      <div class="text-right mb-3">
        <b-select
          v-model="perPage"
          :options="pageOptions"
          style="width: fit-content"
        />
      </div>
      <b-table
        hover
        responsive="sm"
        show-empty
        empty-text="Aucune nature enrégistrée"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(state)="data">
          {{ getName(data.item.state) }}
        </template>
        <template v-slot:cell(arrival_date)="data">
          {{ data.item.arrival_date | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openInfoModal(data.item)">
              <feather-icon
                icon="EyeIcon"
                class="text-info"
              />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item @click="openEditModal(data.item)">
              <feather-icon
                icon="EditIcon"
                class="text-warning"
              />
              <span class="align-middle ml-50">Editer</span>
            </b-dropdown-item>

            <b-dropdown-item @click="openDeleteModal(data.item)">
              <feather-icon
                icon="TrashIcon"
                class="text-danger"
              />
              <span class="align-middle ml-50">Supprimer</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>

    <b-modal
      v-model="showEditModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="updateProcess"
    >
      <b-form-group label="Numéro navire">
        <b-form-input
          v-model="$v.currentShipMovement.shipNumber.$model"
          :formatter="formatter"
          :class="{ 'is-invalid': $v.currentShipMovement.shipNumber.$error }"
        />
        <div
          v-if="$v.currentShipMovement.shipNumber.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.shipNumber.required"
          >Le numéro est requis.</span>
        </div>
      </b-form-group>
      <b-form-group label="Nom du navire">
        <b-form-input
          v-model="$v.currentShipMovement.shipName.$model"
          :formatter="formatter"
          :class="{ 'is-invalid': $v.currentShipMovement.shipName.$error }"
        />
        <div
          v-if="$v.currentShipMovement.shipName.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.shipName.required"
          >Le nom du navire est requis.</span>
        </div>
      </b-form-group>
      <b-form-group label="Type de Consignataire">
        <b-form-select
          v-model="$v.currentShipMovement.shipConsignee.$model"
          :options="consigneeOptions"
          :class="{ 'is-invalid': $v.currentShipMovement.shipConsignee.$error }"
        />
        <div
          v-if="$v.currentShipMovement.shipConsignee.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.shipConsignee.required"
          >Le type de Consignataire est requis.</span>
        </div>
      </b-form-group>
      <b-form-group label="Nature Cargo">
        <b-form-select
          v-model="$v.currentShipMovement.natureCargo.$model"
          :options="cargoOptions"
          :class="{ 'is-invalid': $v.currentShipMovement.natureCargo.$error }"
        />
        <div
          v-if="$v.currentShipMovement.natureCargo.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.natureCargo.required"
          >La nature du cargo est requise.</span>
        </div>
      </b-form-group>
      <b-form-group label="Date d'arrivée">
        <b-form-input
          v-model="$v.currentShipMovement.arrivalDate.$model"
          type="date"
        />
        <div
          v-if="$v.currentShipMovement.arrivalDate.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.arrivalDate.required"
          >La date d'arrivée du navire est requise.</span>
        </div>
      </b-form-group>
      <b-form-group label="Type d'opération">
        <b-form-select
          v-model="$v.currentShipMovement.typeOperation.$model"
          :options="operationOptions"
          :class="{ 'is-invalid': $v.currentShipMovement.typeOperation.$error }"
        />
        <div
          v-if="$v.currentShipMovement.typeOperation.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.typeOperation.required"
          >Le type d'opération est requis.</span>
        </div>
      </b-form-group>

      <b-form-group label="Etat">
        <b-form-select
          v-model="$v.currentShipMovement.state.$model"
          :options="stateList"
          :class="{ 'is-invalid': $v.currentShipMovement.state.$error }"
        />
        <div
          v-if="$v.currentShipMovement.state.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipMovement.state.required"
          >L'état est requis.</span>
        </div>
      </b-form-group>
      <hr>

      <div>
        <div
          v-if="updateProcess"
          class="mt-3 text-center"
        >
          <b-spinner
            label="Loading"
            variant="primary"
          />
        </div>

        <div class="float-left">
          <b-button
            variant="light"
            :disabled="updateProcess"
            @click="closeEditModal"
          >Quitter</b-button>
        </div>

        <div class="float-right">
          <b-button
            variant="outline-success"
            :disabled="updateProcess"
            @click="tryToUpdate"
          >Mofidier</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="deleteProcess"
    >
      <div class="text-justify">
        Voulez-vous vraiment supprimer le mouvement du navire {{ currentShipMovement.shipName }} de numéro {{ currentShipMovement.shipNumber }}  ?
      </div>
      <hr>

      <div>
        <div
          v-if="deleteProcess"
          class="mt-3 text-center"
        >
          <b-spinner
            label="Loading"
            variant="primary"
          />
        </div>

        <div class="float-left">
          <b-button
            variant="light"
            :disabled="deleteProcess"
            @click="closeDeleteModal"
          >Quitter</b-button>
        </div>

        <div class="float-right">
          <b-button
            variant="outline-success"
            :disabled="deleteProcess"
            @click="tryToDelete"
          >Supprimer</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-if="shipConsignee"
      v-model="showInfoModal"
      centered
      hide-footer
      title="Consignataire"
    >
      <div class="text-justify">
        <table>
          <tr>
            <td class="w-50">
              Nom
            </td>
            <td class="w-50">
              {{ shipConsignee.name }}
            </td>
          </tr>
          <tr>
            <td class="w-50">
              Description
            </td>
            <td class="w-50">
              {{ shipConsignee.description }}
            </td>
          </tr>
          <tr>
            <td class="w-50">
              Adresse
            </td>
            <td class="w-50">
              {{ shipConsignee.address }}
            </td>
          </tr>
        </table>
      </div>
      <hr>

      <div>
        <div class="float-right">
          <b-button
            variant="outline-success"
            @click="closeInfoModal"
          >Fermer</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      items: [],
      loaded: false,
      totalItems: null,
      showEditModal: false,
      showDeleteModal: false,
      showInfoModal: false,
      shipConsignee: null,
      currentShipMovement: {
        id: null,
        shipName: null,
        shipNumber: null,
        shipConsignee: null,
        natureCargo: null,
        typeOperation: null,
        state: null,
        arrivalDate: null,
      },
      stateList: [
        { value: null, text: 'Choisir un état' },
        { value: 'MOORING', text: 'MOUILLAGE' },
        { value: 'DOCKED', text: 'A QUAI' },
        { value: 'LOADING', text: 'CHARGEMENT' },
        { value: 'UNLOADING', text: 'DECHARGEMENT' },
        { value: 'DEPARTURE', text: 'DEPART' },
        { value: 'DEHALAGE', text: 'DEHALAGE' },
        { value: 'PREVISION', text: 'PREVISION' },
        { value: 'ENTRY', text: 'ENTREE' },
      ],
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'ship_name', label: 'Nom' },
        { key: 'ship_number', label: 'N° Navire' },
        { key: 'nature_cargo.name', label: 'Nature Cargo' },
        { key: 'type_of_operation.name', label: 'Type d\'opération' },
        { key: 'arrival_date', label: 'Date d\'arrivée' },
        { key: 'state', label: 'Etat' },
        { key: 'action', label: 'Action' },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 15, 20],
      cargoList: [],
      operationList: [],
      consigneeList: [],
    }
  },
  validations: {
    currentShipMovement: {
      shipName: {
        required,
      },
      shipNumber: {
        required,
      },
      shipConsignee: {
        required,
      },
      natureCargo: {
        required,
      },
      typeOperation: {
        required,
      },
      state: {
        required,
      },
      arrivalDate: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      token: 'token',

      updateProcess: 'updateShipMovementProcess',
      updateSuccess: 'updateShipMovementSuccess',
      updateError: 'updateShipMovementError',

      deleteProcess: 'deleteShipMovementProcess',
      deleteSuccess: 'deleteShipMovementSuccess',
      deleteError: 'deleteShipMovementError',
    }),
    cargoOptions() {
      const options = this.cargoList
      const data = [
        { value: null, text: 'Choisir la nature du cargo' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
    consigneeOptions() {
      const options = this.consigneeList
      const data = [
        { value: null, text: 'Choisir le type de Consignataire' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
    operationOptions() {
      const options = this.operationList
      const data = [
        { value: null, text: 'Choisir le type d\'opération' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllConginee()
        this.getAllOperations()
        this.getAllCargo()
      },
    },
    currentPage() {
      this.getAllMovements()
    },
    perPage() {
      this.getAllMovements()
    },

    updateSuccess(val) {
      if (val) {
        switch (val.valid) {
          case 1:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
          default:
            this.closeEditModal()
            this.getAllMovements()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: 'Modifiée avec succès.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    updateError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification',
            text: 'Une erreur est survenue lors de la modification.',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    deleteSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.getAllMovements()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression',
            text: val.res,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression',
            text: 'Une erreur est survenue lors de la suppression.',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    this.getAllMovements()
  },
  methods: {
    ...mapActions(['updateShipMovement', 'deleteShipMovement']),
    getName(item) {
      switch (item) {
        case 'MOORING':
          return 'MOUILLAGE'
        case 'DOCKED':
          return 'A QUAI'
        case 'LOADING':
          return 'CHARGEMENT'
        case 'UNLOADING':
          return 'DECHARGEMENT'
        case 'DEPARTURE':
          return 'DEPART'
        case 'DEHALAGE':
          return 'DEHALAGE'
        case 'ENTRY':
          return 'ENTREE'
        case 'PREVISION':
          return 'PREVISION'
        default:
          break
      }
      return ''
    },
    formatter(value) {
      return value.toUpperCase()
    },
    async getAllMovements() {
      this.loaded = true
      this.items = await this.$http.get(`/api/ship-movement/find-all-paged/${this.currentPage}/${this.perPage}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        this.loaded = false
        this.totalItems = res.data.total_count
        return res.data.items
      })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consignataire',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    openInfoModal(item) {
      this.shipConsignee = item.ship_consignee
      this.showInfoModal = true
    },
    openEditModal(item) {
      this.currentShipMovement = {
        id: item.id.uid,
        shipName: item.ship_name,
        shipNumber: item.ship_number,
        shipConsignee: item.ship_consignee.id.uid,
        natureCargo: item.nature_cargo.id.uid,
        typeOperation: item.type_of_operation.id.uid,
        state: item.state,
        arrivalDate: item.arrival_date ? item.arrival_date.split('T')[0] : new Date().toISOString().split('T')[0],
      }
      this.showEditModal = true
    },
    closeEditModal() {
      this.showEditModal = false
      this.currentShipMovement = {
        id: null,
        shipName: null,
        shipNumber: null,
        shipConsignee: null,
        natureCargo: null,
        typeOperation: null,
        state: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    openDeleteModal(item) {
      this.currentShipMovement = {
        id: item.id.uid,
        shipName: item.ship_name,
        shipNumber: item.ship_number,
        shipConsignee: item.ship_consignee.id.uid,
        natureCargo: item.nature_cargo.id.uid,
        typeOperation: item.type_of_operation.id.uid,
        state: item.state,
      }
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.currentShipMovement = {
        id: null,
        name: null,
        address: null,
        description: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    closeInfoModal() {
      this.showInfoModal = false
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.updateShipMovement({
        id: this.currentShipMovement.id,
        shipName: this.currentShipMovement.shipName,
        shipNumber: this.currentShipMovement.shipNumber,
        shipConsignee: this.currentShipMovement.shipConsignee,
        natureCargo: this.currentShipMovement.natureCargo,
        typeOperation: this.currentShipMovement.typeOperation,
        arrivalDate: this.currentShipMovement.arrivalDate,
        state: this.currentShipMovement.state,
      })
    },
    tryToDelete() {
      this.deleteShipMovement({
        id: this.currentShipMovement.id,
      })
    },
    async getAllConginee() {
      this.consigneeList = await this.$http.get('/api/ship-consignee/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consignataire',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllOperations() {
      this.operationList = await this.$http.get('/api/ship-movement-operation/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consignataire',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllCargo() {
      this.cargoList = await this.$http.get('/api/nature-cargo/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nature Cargo',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
  },
}
</script>
